import React, { useState, useEffect, useRef, useContext, useCallback, forwardRef } from 'react';
import { env } from '../config/env';
import logger from "../utility/logger";
import { AuthContext } from '../context/AuthContext';


import Card from '../components/card';

function Dashboard() {
    const { userId, accessToken } = useContext(AuthContext);
    const [lafPets, setLafPets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchOffset, setSearchOffset] = useState(0);
    // TODO: add searchPostalCode, searchRadius, searchLimit form fields
    const [searchPostalCode, setSearchPostalCode] = useState(95336);
    const [searchRadius, setSearchRadius] = useState(10);
    const [searchLimit, setSearchLimit] = useState(10);


    const fetchLafPets = async () => {
        setLoading(true);
        setError(false);
        await fetch(`${env.api_url}/missing-pets?x=${userId}&o=${searchOffset}&l=${searchLimit}&loc=${searchPostalCode}&r=${searchRadius}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (!res.ok) {
                logger.log('Failed to fetch');
                throw new Error('Failed to fetch');
            }
            return res.json();
        })
        .then(res => {
            setLafPets(res.data);
            const newSearchOffset = res.data.reduce((prev, current) => {
                return (prev && prev.lafId > current.lafId) ? prev.lafId : current.lafId;
            }, 0);
            // console.log(newSearchOffset);
            setSearchOffset(newSearchOffset);
            setLoading(false);
        })
        .catch(err => {
            logger.log(err);
            setError(err);
        });
    }

    useEffect(() => {
        let isMounted = true; // track whether component is mounted
        fetchLafPets().then(() => {
            if (isMounted) setLoading(false);
        });
        return () => { isMounted = false }; // cleanup function to be called when component unmounts
    }, []);

    return (
        <div className="formContainer">
            {
                lafPets.map((pet) => {
                    return <Card key={pet.lafId} pet={pet} />
                })
            }
            {loading && <div>Loading...</div>}
            {error && <div>Error</div>}
        </div>
    );
}

export default Dashboard;