import { useState, useContext, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { env } from '../config/env';
import logger from "../utility/logger";
import ImageUploadField from "../components/ImageUploadField";
import { petColors } from "../utility/pet-colors";

function LostPetForm() {
    const navigate = useNavigate();
    const uploaderRef = useRef();
    const [typeSelections, setTypeSelections] = useState([]);
    const [selectedPetType, setSelectedPetType] = useState('');
    const [selectedPetBreed, setSelectedPetBreed] = useState('');
    const [selectedPetColor, setSelectedPetColor] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isCordova, setIsCordova] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false); // State to handle loading
    const [progress, setProgress] = useState(0); // Track upload progress
    const { userId, accessToken } = useContext(AuthContext);

    useMemo(() => {
        if (window.cordova) {
            setIsCordova(true);
        }

        fetch(`${env.api_url}/pet-types`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (!res.ok) {
                logger.log('Failed to fetch');
                return;
            }
            return res.json();
        }).then(res => {
            setTypeSelections(res.data);
        }).catch(err => {
            logger.log(err);
        });
    }, [accessToken]);

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        setErrorMessage('');
        setSuccessMessage('');

        const petType = e.target.petType.value;
        const petBreed = e.target.petBreed.value;
        const petColor = e.target.petColor.value;
        const petLostArea = e.target.petLostArea.value;
        const radius = e.target.radius.value;

        if (!petType || !petBreed || !petColor || selectedFiles.length === 0 || !petLostArea) {
            setErrorMessage('All fields are required');
            setLoading(false); // Re-enable form in case of validation errors
            return;
        }

        const formData = new FormData();
        formData.append('ownerId', userId)
        formData.append('petType', petType);
        formData.append('petBreed', petBreed);
        formData.append('petColor', petColor);
        formData.append('location', petLostArea);
        formData.append('radius', radius)
        // static answers
        formData.append('isFound', '0');
        formData.append('locationDetails', '');

        if (isCordova) {
            // Cordova-specific logic
            const file = selectedFiles[0];
            window.resolveLocalFileSystemURL(file.path, (fileEntry) => {
                fileEntry.file((fileObj) => {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        const blob = new Blob([new Uint8Array(this.result)], { type: fileObj.type });
                        formData.append('petImage', blob, fileObj.name);
                        // Now proceed with the fetch request
                        sendFormData(formData);
                    };
                    reader.readAsArrayBuffer(fileObj);
                }, (err) => {
                    setErrorMessage('Failed to read file.');
                    setLoading(false);
                    console.error(err);
                });
            }, (err) => {
                setErrorMessage('Failed to access file.');
                setLoading(false);
                console.error(err);
            });
        } else {
            // Normal web browser logic
            formData.append('petImage', selectedFiles[0]); // Normal file

            // Proceed with fetch request
            sendFormData(formData);
        }
    }

    const sendFormData = (formData) => {
        fetch(`${env.api_url}/lost-pets`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('bad response');
            }

            // Stream reading to update progress
            const reader = response.body.getReader();
            const contentLength = +response.headers.get('Content-Length');
            let receivedLength = 0;

            const stream = new ReadableStream({
                start(controller) {
                    function push() {
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            receivedLength += value.length;
                            setProgress((receivedLength / contentLength) * 100);
                            controller.enqueue(value);
                            push();
                        });
                    }
                    push();
                }
            });

            return new Response(stream); // Ensure stream is properly handled
        }).then(response => response.json()) // Convert response stream back to JSON
            .then(data => {
                setSuccessMessage('This pet has been registered as lost. Thank you for your help.');
                document.getElementById("myForm").reset();
                setSelectedPetBreed('');
                setSelectedPetColor('');
                setSelectedPetType('');
                uploaderRef.current.resetState();
            }).catch(error => {
                setErrorMessage('An unexpected error occurred. Please try again later.');
                logger.log('Error:', error);
            }).finally(() => {
                setLoading(false); // Re-enable the form after the request completes
            });
    };

    const handleFilesSelected = (files) => {
        // Process the files here. For example, you can upload them to a server.
        setSelectedFiles(files);
        logger.log(files);
    };

    // const handleSubmitForm = async (e) => {
    //     e.preventDefault();

    //     setErrorMessage('');
    //     setSuccessMessage('');

    //     const petType = e.target.petType.value;
    //     const petBreed = e.target.petBreed.value;
    //     const petColor = e.target.petColor.value;
    //     const petLostArea = e.target.petLostArea.value;
    //     const radius = e.target.radius.value;

    //     if (!petType || !petBreed || !petColor || selectedFiles.length === 0 || !petLostArea) {
    //         setErrorMessage('All fields are required');
    //         setLoading(false); // Re-enable form in case of validation errors
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('ownerId', userId)
    //     formData.append('petType', petType);
    //     formData.append('petBreed', petBreed);
    //     formData.append('petColor', petColor);
    //     formData.append('location', petLostArea);
    //     formData.append('radius', radius)

    //     // static answers
    //     formData.append('petDescription', '');
    //     formData.append('name', '');
    //     formData.append('isFound', '0');
    //     formData.append('locationDetails', '');

    //     await fetch(`${env.api_url}/lost-pets`, {
    //         headers: {
    //             'Authorization': `Bearer ${accessToken}`,
    //             enctype: 'multipart/form-data',
    //         },
    //         method: 'POST',
    //         body: formData,
    //     })
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('bad response');
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             formData.forEach((value, key) => {
    //                 logger.log(key, value);
    //                 formData.delete(key);
    //             });

    //             document.getElementById("myForm").reset();
    //             setSuccessMessage('This pet has been registered as lost. Thank you for your help.');
    //             setSelectedPetBreed('');
    //             setSelectedPetColor('');
    //             setSelectedPetType('');
    //             uploaderRef.current.resetState();
    //         })
    //         .catch((error) => {
    //             setErrorMessage('An unexpected error occurred. Please try again later.');
    //             logger.error('Error:', error);
    //         });
    // }

    // const handleFilesSelected = (files) => {
    //     // Process the files here. For example, you can upload them to a server.
    //     logger.log(files);
    // };


    return (
        <>
            {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
            <div className="formContainer">
                <h2>I lost my pet</h2>
                <form onSubmit={handleSubmitForm} id="myForm">
                    <div className="mb-3">
                        <label htmlFor="petType" className="form-label">Pet Type</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="petType"
                                id="pet_type1"
                                value="dogs"
                                checked={selectedPetType === 'dogs'}
                                onChange={(e) => setSelectedPetType(e.target.value)}
                                disabled={loading} // Disable input during submission
                            />
                            <label className="form-check-label" htmlFor="pet_type1">
                                Dog
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="petType"
                                id="pet_type2"
                                value="cats"
                                checked={selectedPetType === 'cats'}
                                onChange={(e) => setSelectedPetType(e.target.value)}
                                disabled={loading} // Disable input during submission
                            />
                            <label className="form-check-label" htmlFor="pet_type2">
                                Cat
                            </label>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="petBreed" className="form-label">Pet Breed</label>
                        <select
                            className="form-select"
                            id="petBreed"
                            aria-label="Select Pet Type"
                            value={selectedPetBreed}
                            onChange={(e) => {
                                setSelectedPetBreed(e.target.value);
                            }}
                        >
                            <option value="">Select Pet Type</option>
                            {selectedPetType && typeSelections[selectedPetType]?.map((breed) => (
                                <option key={breed.name} value={breed.name}>{breed.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="petColor" className="form-label">Pet Color</label>
                        <select
                            className="form-select"
                            id="petColor"
                            aria-label="Select Pet Color"
                            value={selectedPetColor}
                            onChange={(e) => {
                                setSelectedPetColor(e.target.value);
                            }}
                        >
                            <option value="">Select Pet Color</option>
                            {petColors.map((color) => (
                                <option key={color} value={color}>{color}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="petLostArea" className="form-label">Postal code area Lost</label>
                        <div className="input-group">
                            <input className="form-control" type="text" id="petLostArea" />
                            <select className="form-select" id="radius">
                                <option value="">Radius</option>
                                <option value="5">5 miles</option>
                                <option value="10">10 miles</option>
                                <option value="15">15 miles</option>
                                <option value="20">20 miles</option>
                                <option value="20">25 miles</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="petImage" className="form-label">Pet Image</label>
                        <ImageUploadField onFilesSelected={handleFilesSelected} allowMultiple={false} ref={uploaderRef} />
                    </div>
                    <div className="mb-3">

                    </div>

                    <button className="btn btn-secondary" onClick={(e) => { e.preventDefault(); navigate(-1); }}>Cancel</button>
                    <button type="submit" className="btn btn-primary">Submit</button>

                </form>
            </div>
        </>
    );
}

export default LostPetForm;