import { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';

const ImageUploadField = forwardRef(({ onFilesSelected, allowMultiple }, ref) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [isCordova, setIsCordova] = useState(false);

    useEffect(() => {
        if (window.cordova) {
            setIsCordova(true);
        }
    }, []);

    const handleFieldClick = () => {
        if (isCordova) {
            // Handle Cordova specific file selection here (e.g., Camera or Gallery plugin)
            navigator.camera.getPicture(onCameraSuccess, onCameraError, {
                quality: 50,
                destinationType: Camera.DestinationType.FILE_URI,
                sourceType: Camera.PictureSourceType.PHOTOLIBRARY // Or CAMERA for camera access
            });
        } else {
            fileInputRef.current.click();
        }
    };

    const onCameraSuccess = (imageURI) => {
        const file = {
            name: imageURI.split('/').pop(),
            path: imageURI
        };
        setSelectedFiles([file]);
        onFilesSelected([file]); // Callback to parent
    };

    const onCameraError = (message) => {
        console.error('Camera failed: ' + message);
    };

    const handleFileChange = async (event) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            setSelectedFiles(files);
            await onFilesSelected(files); // Callback with selected files
        }
    };

    useImperativeHandle(ref, () => ({
        resetState() {
            setSelectedFiles([]);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    }));

    return (
        <div>
            <div
                className="border d-flex justify-content-center align-items-center"
                style={{ cursor: 'pointer', height: '100px', borderStyle: 'dotted', borderWidth: '2px', borderColor: '#ced4da' }}
                onClick={handleFieldClick}
            >
                <div>
                    <span className="fa fa-cloud-upload-alt text-muted" style={{ fontSize: '24px' }}></span>
                    <p className="text-muted">Upload Image</p>
                </div>
                {!isCordova && (
                    <input
                        ref={fileInputRef}
                        type="file"
                        multiple={allowMultiple || false}
                        accept="image/png, image/jpeg, image/gif"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                )}
            </div>
            {selectedFiles.length > 0 && (
                <div style={{ display: 'flex', gap: '10px', padding: '10px', flexDirection: 'row' }}>
                    {selectedFiles.map((file) => (
                        <div key={file.name}>
                            {isCordova ? (
                                <img src={file.path} alt={file.name} style={{ width: '100px', height: '100px' }} />
                            ) : (
                                <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '100px', height: '100px' }} />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

export default ImageUploadField;
