import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import DialogModal from '../components/dialog-modal';
import { AuthContext, useAuth } from '../context/AuthContext';
import { env } from '../config/env';
// import defaultIage from '../assets/images/guest-user.webp';
import ImageUploadField from "../components/ImageUploadField";
import ChangePassword from '../components/change-password';
import logger from "../utility/logger";

const HumanProfile = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const uploaderRef = useRef();
    const { userId, accessToken } = useContext(AuthContext);
    const [profile, setProfile] = useState({
        name: '',
        profile_img: '',
        dob: '',
        postalCode: ''
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPwModalOpen, setIsPwModalOpen] = useState(false);
    const [isOkToSubmit, setIsOkToSubmit] = useState(false);
    const [isOkToSubmitPw, setIsOkToSubmitPw] = useState(false);

    useEffect(() => {
        const fetchHumanProfile = async () => {
            await fetch(`${env.api_url}/user-profile?x=${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (!res.ok) {
                        logger.log('Failed to fetch');
                        throw new Error('Failed to fetch');
                    }
                    return res.json();
                }).then(res => {
                    if (Object.keys(res.data).length === 0) {
                        logger.log('Profile not found');
                        return;
                    }
                    res.data.dob = res.data.dob.split('T')[0];
                    setProfile(scrubProfile(res.data));
                }).catch(err => {
                    logger.log(err);
                });
        };
        fetchHumanProfile();
    }, [userId, accessToken]);

    const scrubProfile = (profile) => {
        const scrubbedProfile = {};
        for (const [key, value] of Object.entries(profile)) {
            scrubbedProfile[key] = !value ? '' : value;
        }
        return scrubbedProfile;
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        if (!profile.name || !profile.dob || !profile.postalCode) {
            logger.log('All fields are required');
            setErrorMessage('All fields are required');
            return;
        }

        await fetch(`${env.api_url}/user-profile`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: profile.name, dob: profile.dob, postalCode: profile.postalCode, userId })
        })
            .then(res => {
                if (!res.ok) {
                    logger.log('Failed to Update');
                    setErrorMessage('Failed to Update');
                    throw new Error('Failed to Update');
                }
                return res.json();
            }).then(res => {
                if (Object.keys(res.data).length === 0) {
                    logger.log('Profile not found');
                    setErrorMessage('Profile not found');
                    return;
                }
                res.data.dob = res.data.dob.split('T')[0];
                setProfile(scrubProfile(res.data));
            }).catch(err => {
                logger.log(err);
            });
    };

    const handleCloseModalSubmit = () => {
        const fileUploader = document.getElementById('fileUploader').files[0];
        const formData = new FormData();

        formData.append('profile_img', fileUploader);
        formData.append('userId', userId);

        fetch(`${env.api_url}/user-profile-img`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'enctype': 'multipart/form-data',
            },
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('bad response');
                }
                return response.json();
            })
            .then((res) => {
                formData.forEach((value, key) => {
                    logger.log(key, value);
                    formData.delete(key);
                });

                setIsModalOpen(false);
                uploaderRef.current.resetState();
                res.data.dob = res.data.dob.split('T')[0];
                setProfile(scrubProfile(res.data));
                // imageRef.current.src = `${env.profile_img_url}/${res.data.profile_img}`;
            })
            .catch(err => {
                logger.log(err);
            });
    };

    const handleFormInput = (e) => {
        const { name, value } = e.target;
        setProfile(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
        navigate('/login');
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        uploaderRef.current.resetState();
    };

    const handleClosePwModal = () => {
        setIsPwModalOpen(false);
    };

    const handleClosePwModalSubmit = () => {
        const currentPassword = document.getElementById('currentPassword').value;
        const newPassword = document.getElementById('newPassword').value;
        const confirmPassword = document.getElementById('confirmPassword').value;

        const data = {
            userId: userId,
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        };

        fetch(`${env.api_url}/user-pass`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json' // Set the content type to JSON
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                throw new Error('bad response');
            }
            return response.json();
        }).then(() => {
            setIsPwModalOpen(false);
        }).catch(err => {
            logger.log(err);
        });
    };


    const handleFilesSelected = (files) => {
        files.length > 0 ? setIsOkToSubmit(true) : setIsOkToSubmit(false);
        logger.log(files);
    };

    const handleGotToMessages = (e) => {
        e.preventDefault();
        navigate('/messages');
    };

    const handleGoToChangePassword = (e) => {
        e.preventDefault();
        setIsPwModalOpen(true);
    };

    return (
        <>
            <div className="container">
                <div className='mt-5'>
                    {!profile.profile_img && <img className="profile-img" src='./static/guest-user.webp' alt="Profile" onClick={() => setIsModalOpen(true)} />}
                    {profile.profile_img && <img className="profile-img" src={`${env.profile_img_url}/${profile.profile_img}`} alt="Profile" onClick={() => setIsModalOpen(true)} />}
                </div>
                <div className="profile-form" style={{ textAlign: 'center' }}>
                    <div style={{ textAlign: 'left', paddingLeft: '1em' }}>
                        <label htmlFor="name">Name</label>
                    </div>
                    <input type="text" id="name" name="name" placeholder="Your name" value={profile.name} onChange={handleFormInput} />
                    <div style={{ textAlign: 'left', paddingLeft: '1em' }}>
                        <label htmlFor="location">Postal Code</label>
                    </div>
                    <input type="text" id="postalCode" name="postalCode" placeholder="Postal Code" value={profile.postalCode} onChange={handleFormInput} />

                    <div style={{ textAlign: 'left', paddingLeft: '1em' }}>
                        <label htmlFor="birthdate">Birth Date</label>
                    </div>
                    <input type="date" id="dob" name="dob" value={profile.dob} onChange={handleFormInput} />

                    <div className="d-grid gap-1 mt-2">
                        <a href="#update" className="btn btn-lg btn-primary" onClick={handleSubmitForm}>Update Profile</a>
                        <a href="#password" className="btn btn-lg btn-primary" onClick={handleGoToChangePassword}>Change Password</a>
                    </div>

                    <div className="sign-out mt-2">
                        <a href="#logout" className="btn btn-danger mt-5 text-white" onClick={handleLogout}>Sign Out</a>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <DialogModal
                    title="Profile Image"
                    body={
                        <div className="mb-3">
                            <label htmlFor="petImage" className="form-label">Upload Profile Image</label>
                            <ImageUploadField onFilesSelected={handleFilesSelected} allowMultiple={false} ref={uploaderRef} />
                        </div>
                    }
                    closeDialog={handleCloseModal}
                    closeDialogSubmit={handleCloseModalSubmit}
                    closeDialogSubmitDisabled={!isOkToSubmit}
                    closeText="Cancel"
                    closeSubmitText="Upload Image"
                    hideCloseOnly={false}
                />
            )}
            {isPwModalOpen && (
                <DialogModal
                    title="Change Password"
                    body={
                        <ChangePassword _callbackIsPWOk={(isOk) => setIsOkToSubmitPw(isOk)} />
                    }
                    closeDialog={handleClosePwModal}
                    closeDialogSubmit={handleClosePwModalSubmit}
                    closeDialogSubmitDisabled={!isOkToSubmitPw}
                    closeText="Cancel"
                    closeSubmitText="Change Password"
                    hideCloseOnly={false}
                />
            )}
        </>
    );
};

export default HumanProfile;
